body, html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  font-family: 'Satoshi', sans-serif;
  background-color: #000c1f;
  overflow: hidden;
  overscroll-behavior-y: none;
}

html {
  height: -webkit-fill-available;
}

.chat-page-container {
  height: 100vh;
  height: 100svh;
  height: calc(var(--vh, 1vh) * 100);
  height: calc(var(--svh, 1svh) * 100);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
}

.chat-progress-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  box-sizing: border-box;
}

.chat-progress-bar {
  height: 5px;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 1px;
  transition: background-color 0.3s ease;
}

.chat-progress-bar.active {
  background-color: white;
}

.chat-container {
    position: fixed;
    width: 100%;
    top: 25vh;
    top: calc(25vh + env(safe-area-inset-top));
    top: 25svh;
    top: calc(25svh + env(safe-area-inset-top));
    left: 50%;
    transform: translateX(-50%);
    max-height: calc(100vh - 160px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    max-height: calc(100svh - 160px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    display: flex;
    flex-direction: column;
    color: white;
    align-items: flex-start;
    max-width: 800px;
}

.chat-history {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  padding: 10px;
  overflow-y: auto;
  scroll-behavior: smooth;
  margin: 10px 0;
}

.message {
  margin-bottom: 10px;
  line-height: 1.2;
  text-align: left;
  font-size: 28px;
  color: #d3d3d3;
}

.message.latest {
  color: white;
}

.typing-indicator {
  display: inline-block;
  vertical-align: middle;
}

.typing-indicator::after {
  content: '...';
  display: inline-block;
  animation: ellipsis 1.2s infinite;
}

@keyframes ellipsis {
  0% { content: '.'; }
  33% { content: '..'; }
  66% { content: '...'; }
}

.message.typing {
  color: #c0c0c0;
}

.input-container {
  display: flex;
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px 0;
  align-items: flex-end;
  width: 90%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: auto;
}

#user-input {
  flex: 1;
  background: transparent;
  border: none;
  color: white;
  font-size: 28px;
  outline: none;
  width: 100%;
  padding: 10px 0;
  overflow-y: auto;
  resize: none;
  min-height: 40px;
  max-height: 200px;
  white-space: pre-wrap;
  word-wrap: break-word;
  box-sizing: border-box;
  line-height: 1.2;
}

#user-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.input-container button {
  background: transparent;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
}

.input-container button:hover {
  background: #c0c0c0;
}

.hint {
  text-align: right;
  width: 95%;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  margin: 10px 0;
}

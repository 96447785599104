.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 120px;
  text-align: center;
}

.instructions {
  font-size: 16px;
  margin-bottom: 30px;
}

.loading-spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 0 auto 30px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.container .styled-button {
  width: 220px;
  height: 60px;
  font-size: 1em;
  text-decoration: none;
}

.email-instructions {
  margin-bottom: 20px;
}

.error-message {
  color: #ff6b6b;
  font-size: 18px;
  margin-top: 20px;
}
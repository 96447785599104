body, html {
  margin: 0;
  font-family: 'Satoshi', sans-serif;
  overflow-y: auto;
  color: white;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;

  background-image: url('space_bg.jpg'); /* Adjust the path as needed */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; /* This keeps the background fixed while scrolling */
  min-height: 100vh;
  min-height: 100svh;
}

@media (max-width: 768px) {
  body, html {
    background-image: url('base_bg.jpg');
  }
}

.app-container {
  background-image: none;
}

body::-webkit-scrollbar {
  display: none;
}

.detailed-page-container {
  height: auto;
  min-height: 100vh;
  min-height: 100svh;
  margin-top: 100px;
  max-width: 800px;
  width: 95%;
  overflow: visible;
}

/*
h1, h2, h3 {
  margin-top: 20px;
}

p {
  margin: 10px 0;
}
*/

.section-container {
  margin-bottom: 30px;
  padding: 10px;
}

.detailed-page-container .audio-player-container {
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 0;
  padding: 30px;
  text-align: center;
  max-width: 800px;
}

.audio-player-container .preview-instruction p {
  font-size: 22px;
  margin: 0;
}

.audio-player-container .audio-container {
  margin-top: 40px;
  margin-bottom: 0;
}

audio {
  width: 100%;
  max-width: 400px;
  background-color: rgba(255,204,255,0.1);
}

.button-container {
  margin: 40px;
  text-align: center;
}

.button-container .styled-button {
  font-size: 1em;
  margin-top: 20px;
  max-width: 300px;
}

.background-glow {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, rgba(255,204,255,0.2) 0%, rgba(18,18,18,0) 70%);
  pointer-events: none;
  z-index: -1;
}

.pricing-heading {
  margin-bottom: 0;
  padding: 0 10px;
}

.pricing-heading p {
  font-size: 24px;
}

/* Pricing Options Section */
.pricing-options {
  display: flex;
  gap: 8px;
  padding: 0 10px;
}

@media (max-width: 768px) {
  .pricing-options {
    flex: 1 1 100%; /* Single column on smaller screens */
    justify-content: center;
  }
}

.spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

.option {
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background for pricing options */
  padding: 5px;
  border-radius: 12px;
  text-align: center;
  position: relative; /* Add position relative to use absolute positioning for ribbons */
  font-size: 24px;
}

.option p {
  font-size: 24px;
  margin-top: 0px;
  color: #c0c0c0;
}

.option h2 {
  margin-bottom: 0px;
}

.option b {
  margin-bottom: 0px;
}

/* Top Bars */
.option.starter::before,
.option.most-popular::before,
.option.best-value::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 12%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.option.starter {
  /* border: 1px solid rgb(0, 0, 0, 0.3); */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for glow effect */
}

.option.starter:hover {
  box-shadow: 0 0 20px rgba(0, 123, 255, 0.7); /* Stronger silver glow effect on hover */
}

.option.starter::before {
  background-color: #EFECE8;
  z-index: 1;
}

.option.most-popular {
  /* border: 1px solid #F47715; */ /* Thin blue border */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for glow effect */
}

.option.most-popular:hover {
  box-shadow: 0 0 20px rgba(0, 123, 255, 0.7); /* Stronger blue glow effect on hover */
}

.option.most-popular::before {
  background-color: #EFECE8;
  z-index: 1;
}

.option.best-value {
  /* border: 1px solid #03BF62; */ /* Thin silver border */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for glow effect */
}

.option.best-value:hover {
  box-shadow: 0 0 20px rgba(0, 123, 255, 0.7); /* Stronger silver glow effect on hover */
}

.option.best-value::before {
  background-color: #EFECE8;
  z-index: 1;
}

/*
.option.starter::before {
  background-color: rgba(255, 255, 255, 0.2);
}
*/

.option .styled-button {
  border-radius: 12px;
  margin-bottom: 0;
  width: 90%;
  height: 10%;
}

@media (max-width: 768px) {
  .option .styled-button {
    font-size: 20px;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    margin: 2.5%;
    margin-bottom: 20px;
    padding: 5px;
    width: 95%;
  }
}

.option-heading {
  position: relative;
  z-index: 2;
  margin-bottom: 40px;
  margin-top: 0.5vh;
  margin-top: 0.5svh;
}

.option-heading b {
  font-size: 22px;
  color: #4D49EA;
}

@media (max-width: 768px) {
  .option-price b {
    font-size: 0.9em;
  }
}

.option-total-price p {
    font-size: 0.75em;
}

.discount {
    font-size: 1em;
    color: #EFECE8;
    font-weight: 700;
    background-color: #00b09f;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    padding: 0 4px;
    max-width: 90%;
    margin: 0 5%;
}

/* Introductory Offer Section */
.introductory-offer {
  text-align: center;
}

.features-section h2 {
  margin-bottom: 40px;
}

/* Features Section */
.features {
  display: grid;
  grid-template-columns: 1fr 2fr; /* Four columns */
  gap: 50px 0; /* Gap between grid items */
  list-style-type: none;
  padding: 0;
  font-size: 24px;
}

.section-container .features-section {
  margin-top: 20px;
}

.section-container .features-section h2 {
  margin: 0px;
  padding: 0 30px;
}

.feature-item {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.feature-icon {
  position: relative;
  width: 80px; /* Adjust width as needed */
}

.feature-text {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: top;
  text-align: left;
  margin: 0;
}

.feature-text p {
  font-size: 22px;
  margin: 0;
}

.feature-text h3 {
  margin-bottom: 10px;
  margin-top: 0;
}

.additional-info {
  margin: 0 10px;
  margin-bottom: 30px;
  text-align: left;
  justify-content: left;
}

.additional-info p {
  font-size: 24px;
  margin: 40px 0;
}

.additional-info h2 {
  margin: 0;
  text-align: center;
}

.additional-info .styled-button {
  width: 95%;
  font-size: 1.05em;
  max-width: 360px;
}

.faq-section {
  margin-bottom: 0;
}

.faq-section h2 {
  margin-bottom: 0;
}

.faq {
  list-style-type: none;
  padding: 10px;
  text-align: left;
  justify-content: left;
  margin-top: 0;
  font-size: 24px;
}

.faq p {
  font-size: 24px;
  margin-bottom: 20px;
}

.faq li {
  margin: 40px 0;
  text-align: left;
  justify-content: left;
}

.faq a {
  color: skyblue;
}

.payment-icon-wrapper {
  display: block;
  max-width: 800px;
  margin: 0 auto;
}

.payment-icon {
  width: 80%;
  height: auto;
  display: inline-block;
}

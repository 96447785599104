body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #121212;
  color: white;
  font-family: 'Satoshi', sans-serif;
}

.container-affirmations {
  position: relative;
  height: 100%;
  min-height: 80vh;
  min-height: 80svh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-affirmations h1 {
  text-size: 1.8em;
}

.container-affirmations h2 {
  text-size: 1.4em;
}

.header {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 1.5em;
}

.progress-container {
  width: 40%;
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}

.progress-bar {
  width: 100%;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.2); /* Slightly visible background */
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1); /* Subtle glow for the background */
}

.progress {
  height: 100%;
  width: 0%; /* Will be controlled by JavaScript */
  background: linear-gradient(90deg, #ff66ff, #ff99ff); /* Gradient background */
  border-radius: 4px;
  transition: width 0.5s linear;
  box-shadow:
    0 0 10px #ff99ff,
    0 0 20px #ff99ff,
    0 0 30px #ff99ff;
  animation: glow 1.5s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow:
      0 0 10px #ff99ff,
      0 0 20px #ff99ff,
      0 0 30px #ff99ff;
  }
  to {
    box-shadow:
      0 0 20px #ff99ff,
      0 0 30px #ff99ff,
      0 0 40px #ff99ff;
  }
}

.progress-text {
  position: absolute;
  top: -30px;
  left: 0; /* Will be controlled by JavaScript */
  transform: translateX(-50%);
  font-size: 20px;
  color: #ffffff;
  transition: left 0.5s linear;
}

.heading {
  text-align: center;
  margin-bottom: 60px;
}

.user-name {
  font-size: 24px;
}

.generating-text {
  font-size: 28px;
  font-weight: bold;
}

.affirmations-animation {
  position: relative;
  height: 50%;  /* Fixed height */
  width: 80%;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  mask-image: linear-gradient(to bottom, transparent 0%, black 20%, black 80%, transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 20%, black 80%, transparent 100%);
}

.ribbon {
  position: absolute;
  top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.sentence {
  width: 100%;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 28px;
  padding: 10px 0;
  color: white;
  font-style: italic;
}

.list {
  font-size: 0.6em;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-Regular.woff2') format('woff2'),
       url('../fonts/Satoshi-Regular.woff') format('woff'),
       url('../fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-Bold.woff2') format('woff2'),
       url('../fonts/Satoshi-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Satoshi', sans-serif;
  font-size: 1.2em;
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px;
}

.logo-img {
  max-width: 240px;  /* Adjust as needed for desired display size */
  height: auto;
}

.home-container {
  background-size: cover;
  background-position: center;
  height: 100vh;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  max-width: 800px;
}

.home-container .content .pre-title {
  font-size: 18px;
}

.content {
  max-width: 800px;
  padding: 20px;
  z-index: 1;
}

.main-title {
  font-size: 48px;
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
  padding: 10px;
}

.subtitle {
  font-size: 24px;
  margin-bottom: 60px;
}

.styled-button {
    /* Updated Background Gradient to Violet */
    background: linear-gradient(to bottom, #7A7FEF 0%, #4D49EA 50%, #3A34C6 100%);

    /* Updated Text Color to #EFECE8 */
    color: #EFECE8;

    border-radius: 20px; /* Slightly rounded corners */
    width: 240px; /* Fixed width */
    height: 80px; /* Fixed height to make it square */
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;

    /* Updated Box Shadows to Complement Violet */
    box-shadow:
        0px 6px 10px rgba(77, 73, 234, 0.3), /* Outer glow */
        inset 0px 1px 3px rgba(255, 255, 255, 0.5); /* Top inner highlight */

    /* Updated Border Color to a Darker Violet */
    border: 1px solid #3A34C6;

    /* Text Shadow remains the same */
    text-shadow: 0px 1px 2px rgba(255, 255, 255, 0.3);

    /* Transition and Positioning remain unchanged */
    transition: all 0.3s ease;
    margin: 0 auto;
    position: relative;
}

.styled-button:hover {
    /* Updated Hover Background Gradient to Lighter Violet Shades */
    background: linear-gradient(to bottom, #8C8FFF 0%, #6A6DEB 50%, #4D49EA 100%);

    /* Updated Hover Box Shadows for Enhanced Violet Glow */
    box-shadow:
        0px 8px 15px rgba(77, 73, 234, 0.4), /* Enhanced outer glow */
        inset 0px 1px 3px rgba(255, 255, 255, 0.6); /* Lighter inner highlight */
}

.styled-button:active {
    /* Updated Active Background Gradient to Deeper Violet Shades */
    background: linear-gradient(to bottom, #6A6DEB 0%, #4D49EA 50%, #3631A1 100%);

    /* Updated Active Box Shadows for Pressed Effect */
    box-shadow:
        inset 0px 2px 4px rgba(0, 0, 0, 0.2), /* Deeper inner shadow when pressed */
        0px 4px 8px rgba(77, 73, 234, 0.2); /* Reduced outer glow when pressed */

    /* Maintain the pressed effect */
    transform: translateY(1px);
}

.styled-button .arrow {
    margin-left: 10px;
    transition: transform 0.3s ease;
}

.styled-button:hover .arrow {
    transform: translateX(5px);
}

.no-requirement {
  font-size: 16px;
  margin-top: 20px;
  opacity: 0.8;
}

@media (max-width: 768px) {

  .main-title {
    font-size: 34px;
  }

  .subtitle {
    font-size: 20px;
  }
}

.footer {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer a {
  color: #ffffff;
  text-decoration: none;
  font-size: 0.6em;
}

.footer a:hover {
  text-decoration: underline;
}

.popup-overlay {
  position: fixed;
  width: 90%;
  max-width: 800px;
  font-size: 18px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  margin: auto;
}

.popup-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 70vh;
  max-height: 70svh;
  overflow-y: auto;
}

.popup-content h2 {
  margin-top: 60px;
  color: #000;
  font-size: 1.5em;
  margin-bottom: 40px;
}

.popup-content h3 {
  margin-top: 40px;
  color: #000;
  font-size: 1.2em;
  margin-bottom: 20px;
  text-align: left;
}

.popup-content p, .popup-content ul, .popup-content ol {
  color: #333;
  line-height: 1.2;
  font-size: 1em;
  margin-bottom: 15px;
  text-align: left;
}

.popup-content ul, .popup-content ol {
  padding-left: 10px;
  text-align: left;
  margin-bottom: 30px;
}

.popup-content li {
  margin-bottom: 10px;
  text-align: left;
}

.popup-content button {
  background-color: #7A7FEF;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1em;
}

.popup-content button:hover {
  background-color: #3A34C6;
}
/* index.css */
@font-face {
  font-family: 'Satoshi';
  src: url('./fonts/Satoshi-Regular.woff2') format('woff2'),
       url('./fonts/Satoshi-Regular.woff') format('woff'),
       url('./fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./fonts/Satoshi-Bold.woff2') format('woff2'),
       url('./fonts/Satoshi-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Satoshi', sans-serif;
  color: white;
  background-color: #000c1f;
}

h1, h2, h3, h4, h5, h6, p, a, span, div, li, ul, ol, button, input, textarea {
  font-family: 'Satoshi', sans-serif;
}

.app-container {
  position: relative;
  height: 100vh;
  height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url('components/space_bg.jpg');
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}

@media (max-width: 768px) {
  .app-container {
    background-image: url('components/base_bg.jpg');
  }
}

.popup-pause {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-pause-content {
  background-image: url('components/space_bg.jpg');
  background-size: cover; /* Ensures the image covers the entire div */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: white;
  margin: 20px auto;
}

.popup-pause-content h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.popup-pause-content p {
  font-size: 18px;
  margin-bottom: 30px;
}

.popup-pause-content button {
  display: inline-flex;
  height: 60px;
  width: 180px;
  margin: 10px;
  font-size: 0.9em;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Satoshi', sans-serif;
  color: white;
  background-color: #121212; /* Dark background to match screenshot */
}

.audio-player-container {
  max-width: 90%;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
  max-width: 800px;
}

.preview-instruction p {
  font-size: 22px;
  margin: 0;
}

.audio-container {
  margin-top: 40px;
  margin-bottom: 40px;
}

audio {
  width: 100%;
  max-width: 400px;
  background-color: rgba(255,204,255,0.1);
}

.button-container {
  margin: 40px;
  text-align: center;
}

.button-container .styled-button {
  font-size: 1em;
  margin-top: 20px;
  width: 300px;
}

.background-glow {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, rgba(255,204,255,0.2) 0%, rgba(18,18,18,0) 70%);
  pointer-events: none;
  z-index: -1;
}

.post-preview {
  opacity: 0;
  transition: opacity 2s ease-in-out;
  width: 100%;

}

.post-preview.visible {
  opacity: 1;
}

